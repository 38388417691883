& {
  padding-top: 0 !important;
}

.page {
  margin-bottom: 0 !important;
}

.navbar-default {
  padding: 15px 0;
  background-color: rgba($brand-darker, 0);

  @media (min-width: $grid-float-breakpoint) {
    padding: 25px 0;

    .navbar-brand {
      font-size: 3rem;
    }

    .navbar-nav > li > a {
      padding-top: 1.9rem;
      padding-bottom: 1.9rem;
    }
  }
}

header {
  &.splash-intro {
    position: relative;
    background: transparent;
    color: #fff;
    padding-top: 78px + 30px;
    padding-bottom: 30px;

    @media (min-width: $grid-float-breakpoint) {
      padding-top: 112px + 30px;
      padding-bottom: 30px;
    }

    h1, h2 {
      span {
        white-space: nowrap;
      }
    }

    h1 {
      margin-bottom: 2rem;
      font-size: 4rem;
      font-weight: 700;
      line-height: 4.5rem;

      @media (min-width: $grid-float-breakpoint) {
        margin-bottom: 3rem;
        font-size: 6rem;
        line-height: 6.75rem;
      }
    }

    h2 {
      margin: 0;
      font-size: 2rem;
      font-weight: 300;
      line-height: 2.5rem;
      color: $brand-light;

      @media (min-width: $grid-float-breakpoint) {
        font-size: 3rem;
        line-height: 3.75rem;
      }

      &:last-child {
        margin-bottom: 1rem;

        @media (min-width: $grid-float-breakpoint) {
          margin-bottom: 4rem;
        }
      }
    }

    .lead-in {
      margin-top: 3.5rem;
      font-size: 1.8rem;
      line-height: 1.5em;

      @media (min-width: $grid-float-breakpoint) {
        margin-top: 5rem;
        font-size: 2.25rem;
        line-height: 1.5em;

        span {
          display: inline-block;
        }
      }
    }

    .actions {
      margin: 4rem 0 0.5em 0;

      @media (min-width: $grid-float-breakpoint) {
        margin-top: 6rem;
        margin-bottom: 3rem;
      }

      .btn {
        background-color: rgba(#fff, 0.7);
        border: none;
        color: $text-color;
        @include transition(all ease 0.35s);

        &:hover, &:focus {
          background-color: rgba(#fff, 0.9);
        }
      }
    }
  }
}

.section-heading,
.section-subheading {
  text-align: center;
}

.about-section {
  .intro {
    font-size: 2rem;
    text-align: center;
    line-height: 1.4em;

    @media (min-width: $grid-float-breakpoint) {
      > span {
        white-space: nowrap;
      }
    }
  }

  .actions {
    text-align: center;

    .btn {
      opacity: 0.7;
      @include transition(all ease 0.35s);

      &:hover, &:focus {
        opacity: 0.9;
      }
    }
  }
}

.contact-section {
  background-color: transparent;
  background-image: url("../images/map-image.png");
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  padding-bottom: 150px;

  .contact-info {
    text-align: center;
    margin-bottom: 30px;

    .caption {
      text-transform: uppercase;
      color: $brand-light;
    }

    .value {
      display: inline-block;
      font-weight: 300;
      font-size: 1.8rem;

      &.address {
        text-align: left;

        .name {
          margin-bottom: 10px;
          font-weight: bold;

          > span, > img {
            display: inline-block;
            vertical-align: middle;
          }

          .flag {
            width: 1.6rem;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
