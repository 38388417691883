@mixin vertical-gradient($start, $end, $solid: mix($start, $end, $weight: 50%), $postfix: ())
{
	background: $solid $postfix;
	background: -moz-linear-gradient(top, $start 0%, $end 100%) $postfix;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start), color-stop(100%, $end)) $postfix;
	background: -webkit-linear-gradient(top, $start 0%, $end 100%) $postfix;
	background: -o-linear-gradient(top, $start 0%, $end 100%) $postfix;
	background: -ms-linear-gradient(top, $start 0%, $end 100%) $postfix;
	background: linear-gradient(to bottom, $start 0%, $end 100%) $postfix;
	_background: $solid $postfix;
}

@mixin horizontal-gradient($start, $end, $solid: mix($start, $end, $weight: 50%), $postfix: ())
{
	background: $solid $postfix;
	background: -moz-linear-gradient(left, $start 0%, $end 100%) $postfix;
	background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start), color-stop(100%, $end)) $postfix;
	background: -webkit-linear-gradient(left, $start 0%, $end 100%) $postfix;
	background: -o-linear-gradient(left, $start 0%, $end 100%) $postfix;
	background: -ms-linear-gradient(left, $start 0%, $end 100%) $postfix;
	background: linear-gradient(to right, $start 0%, $end 100%) $postfix;
	_background: $solid $postfix;
}

@mixin rounded($radius: 5px)
{
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin custom-rounded($top-left: 5px, $top-right: 5px, $bottom-right: 5px, $bottom-left: 5px)
{
	-webkit-border-top-left-radius: $top-left;
	-webkit-border-top-right-radius: $top-right;
	-webkit-border-bottom-right-radius: $bottom-right;
	-webkit-border-bottom-left-radius: $bottom-left;
	-moz-border-radius-topleft: $top-left;
	-moz-border-radius-topright: $top-right;
	-moz-border-radius-bottomright: $bottom-right;
	-moz-border-radius-bottomleft: $bottom-left;
	border-top-left-radius: $top-left;
	border-top-right-radius: $top-right;
	border-bottom-right-radius: $bottom-right;
	border-bottom-left-radius: $bottom-left;
}

@mixin inline-block($vertical-align: middle)
{
	display: inline-block !important;
	vertical-align: $vertical-align;
	*display: inline !important;
}

@mixin center-box
{
	margin-left: auto !important;
	margin-right: auto !important;
}

@mixin font-size($sz: 1em, $lh: $line-height-base)
{
	font-size: $sz;
	line-height: $lh;
}

@mixin no-overflow
{
	white-space: nowrap;
	overflow: hidden;
}

@mixin box-shadow($params...)
{
	-webkit-box-shadow: $params;
	-moz-box-shadow: $params;
	box-shadow: $params;
}

@mixin splash
{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	_position: absolute;
}

@mixin sr-only
{
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0 none;
}

@mixin transition($params...)
{
	-webkit-transition: $params;
	-moz-transition: $params;
	-o-transition: $params;
	transition: $params;
}
