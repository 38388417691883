@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-bold-webfont.woff2') format('woff2'),
       url('../fonts/titillium-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-bolditalic-webfont.woff2') format('woff2'),
       url('../fonts/titillium-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-light-webfont.woff2') format('woff2'),
       url('../fonts/titillium-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-lightitalic-webfont.woff2') format('woff2'),
       url('../fonts/titillium-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-regular-webfont.woff2') format('woff2'),
       url('../fonts/titillium-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium';
  src: url('../fonts/titillium-regularitalic-webfont.woff2') format('woff2'),
       url('../fonts/titillium-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
