.hidden
{
	display: none;
}

.center-box
{
	@include center-box;
}

.inline
{
	@include inline-block;
}

.block
{
	display: block;
}

.top
{
	vertical-align: top;
}

.middle
{
	vertical-align: middle;
}

.baseline
{
	vertical-align: baseline;
}

.bottom
{
	vertical-align: bottom;
}

.indent, .indent-1
{
	margin-left: 3em;
}

.indent-2
{
	margin-left: 6em;
}

.indent-3
{
	margin-left: 9em;
}

.no-margin
{
	margin: 0 !important;
}

.no-padding
{
	padding: 0 !important;
}

.nowrap
{
	white-space: nowrap;
}

.clear
{
	clear: both;
}

.left, .l
{
	text-align: left !important;
}

.right, .r
{
	text-align: right !important;
}

.center, .c
{
	text-align: center !important;
}

.justify, .j
{
	text-align: justify !important;
}

.small
{
	@include font-size(0.8em);
}

.large
{
	@include font-size(1.2em);
}

.sans-serif
{
	font-family: $font-family-sans-serif !important;
}

.serif
{
	font-family: $font-family-serif !important;
}

.monospace
{
	font-family: $font-family-monospace !important;
}

.normal
{
	font-weight: normal !important;
	font-style: normal !important;
}

.bold
{
	font-weight: bold !important;
}

.italic
{
	font-style: italic !important;
}

.black
{
	color: #000 !important;
}

.gray
{
	color: #666 !important;
}

.light-gray
{
	color: #888 !important;
}

.red
{
	color: $red !important;
}

.blue
{
	color: $blue !important;
}

.green
{
	color: $green !important;
}

.orange
{
	color: $orange !important;
}

.brand-color
{
	color: $brand-primary !important;
}

a.red, .red a
{
	color: $red !important;
}

a.green, .green a
{
	color: $green !important;
}

a.blue, .blue a
{
	color: $blue !important
}

div.table
{
	display: table;
	width: 100%;
	height: 100%;

	div.cell
	{
		display: table-cell;
		vertical-align: middle;
	}
}

abbr
{
	text-decoration: none;
}
