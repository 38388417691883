html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5em;
  padding-top: 70px;
  @include transition(padding-top ease .35s);

  ::selection,
  ::-moz-selection {
    text-shadow: none;
    background: $brand-primary;
    color: white;
  }
  
  img::selection,
  img::-moz-selection {
    background: 0 0;
  }

  &:not(.page-index) {
    .only-index {
      display: none !important;
    }
  }

  &.page-index {
    .except-index {
      display: none !important;
    }
  }

  > .background {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $brand-darker;
    background-image: url('../images/matterhorn-bg.jpg');
    background-size: cover;
    background-position: center;
    z-index: 0;
  }

  > .page {
    position: relative;
    z-index: 1;
    margin-bottom: 200px;
    background: rgba(#000, 0.5);
  }
}

a {
  color: $brand-primary;

  &:hover, &:focus, &:active {
    outline: 0;
    color: $brand-secondary;
  }
}

ul.bullets li
{
  list-style: none;
  text-indent: -.6em;
  margin: 0.2em 0;
  
  &:before
  {
    content: "\25A0";
    display: inline-block;
    position: relative;
    width: .6em;
    top: -.1em;
    color: $brand-primary;
  }
  
  ul li:before
  {
    color: #888;
  }
}

.btn-xl {
  padding: 20px 40px;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.actions {
  margin: 20px 0 10px 0;

  .btn {
    margin: 0 10px 10px 10px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.navbar-default {
  padding: 1.1rem 0;
  background-color: rgba($brand-darker, 0);
  border-color: transparent;
  border: 0;
  @include transition(all ease .35s);

  .navbar-brand {
    margin: 0.25em 0 0.25em 15px !important;
    background: url('../images/logo-white.svg') top left no-repeat;
    background-size: contain;
    padding: 0;
    width: 13em;
    height: 1.5em;
    font-size: 2.4rem;
    opacity: 0.8;
    color: transparent !important;
    @include transition(all ease .35s);
  }

  .navbar-toggle {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    border-color: rgba(#fff, 0);
    background-color: rgba(#fff, 0);
    @include transition(all ease .15s);

    @media (max-width: $grid-float-breakpoint - 1px) {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
    }

    &:active, &:hover, &:focus {
      background-color: rgba(#fff, 0.3);
    }

    .icon-bar {
      background-color: #fff;
    }
  }

  .navbar-collapse {
    border: 0 none !important;
    @include box-shadow(none);

    @media (max-width: $grid-float-breakpoint - 1px) {
      margin-bottom: -5px;
      padding-top: 10px;
    }

    &.in {
      background: $brand-darker;
    }

    .navbar-nav {
      > li > a {
        text-transform: uppercase;
        font-weight: 400;
        padding-top: 1.1rem;
        padding-bottom: 1.1rem;
        letter-spacing: 1px;
        color: #fff;
        @include transition(all ease .35s);

        &:focus, &:hover {
          outline: 0;
          color: $brand-light;
        }
      }

      > .active > a {
        border-radius: $border-radius-base;
        background: $brand-primary;

        @media (max-width: $grid-float-breakpoint - 1px) {
          border-radius: 0;
        }

        &:focus, &:hover {
          color: #fff;
        }
      }
    }
  }

  &.navbar-shrink {
    padding: 15px 0 !important;
    background-color: rgba($brand-darker, 1) !important;

    .navbar-brand {
      font-size: 2.4rem !important;
    }

    .navbar-nav > li > a {
      padding-top: 1.1rem !important;
      padding-bottom: 1.1rem !important;
    }
  }
}

header {
  background: rgba(#fff, 0.8);
  text-align: center;

  h1 {
    margin: 2.5rem 0;
  }
}

main {
  padding: 30px 0;
  background: rgba(#fff, 0.9);
}

section {
  position: relative;
  padding: 30px 0;
  background: rgba(#fff, 0.9);

  > .anchor {
    visibility: hidden;
    position: absolute;
    z-index: -1000;
    top: -77px;
  }

  &.alternative-bg {
    background: rgba(#fff, 0.8);
  }

  @media (min-width: $grid-float-breakpoint) {
    padding: 50px 0;
  }

  h2.section-heading {
    margin: 0.2em 0 1.2em 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.25em;

    @media (min-width: $grid-float-breakpoint) {
      font-size: 4rem;
      line-height: 1.25em;
    }
  }

  h3.section-subheading {
    margin: -1em 0 1.5em 0;
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1.25em;
    color: #666;

    @media (min-width: $grid-float-breakpoint) {
      font-size: 3rem;
      line-height: 1.25em;
    }
  }

  .actions {
    margin-top: 40px;

    @media (min-width: $grid-float-breakpoint) {
      margin-top: 30px;
    }
  }
}
