@import "functions";

$blue:              hsl(210, 90, 40);
$light-blue:        adjust-color($blue, $lightness: +45%, $saturation: -30%);

$red:               hsl(0, 100, 40);

$green:             hsl(90, 90, 25);
$light-green:       adjust-color($green, $lightness: +45%, $saturation: -30%);

$yellow:            hsl(45, 100, 45);
$light-yellow:      adjust-color($yellow, $lightness: +25%);

$orange:            hsl(35, 100, 45);
$light-orange:      adjust-color($orange, $lightness: +25%);

@import "mixins";
@import "fonts";

@import "bootstrap-config";
@import "bootstrap/bootstrap";

@import "general";
@import "layout";

body.page-index {
  @import "pages/index";
}
