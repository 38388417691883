$gray-darker: #222222;
$gray-dark: #333333;
$gray: #555555;
$gray-light: #999999;
$gray-lighter: #eeeeee;

$brand-hue: 140;
$brand-primary: whsl($brand-hue, 220, 80);
$brand-secondary: lighten($brand-primary, 20%);
$brand-success: #6a973e;
$brand-warning: #df9820;
$brand-danger: #da2525;
$brand-info: #207d8a;
$brand-dark: whsl($brand-hue, 240, 60);
$brand-darker: darken($brand-primary, 25%);
$brand-light: whsl($brand-hue, 180, 180);

/* Set navbar collapse breakpoint to $screen-md-min */
$grid-float-breakpoint: 992px;

$body-bg: #fff;
$text-color: $gray-dark;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 10%);
$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;
$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;
$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;
$border-radius-base: 5px;
$border-radius-large: $border-radius-base;
$border-radius-small: $border-radius-base;
$line-height-large: 1.33;
$line-height-small: 1.5;
$component-active-bg: $brand-primary;

$font-family-sans-serif: Titillium, Helvetica Heue, Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, Times New Roman, Times, serif;
$font-family-monospace: Andale Mono, Consolas, Lucida Console, monospace;
$font-family-base: $font-family-sans-serif;
$font-size-base: 16px;
$font-size-large: ceil($font-size-base * 1.2);
$font-size-small: ceil($font-size-base * 0.8);
$font-size-h1: floor(($font-size-base * 2.5));
$font-size-h2: floor(($font-size-base * 2));
$font-size-h3: floor(($font-size-base * 1.6));
$font-size-h4: floor(($font-size-base * 1.2));
$font-size-h5: $font-size-base;
$font-size-h6: floor(($font-size-base * 0.8));
$line-height-base: 1.5;
$line-height-computed: floor($font-size-base * $line-height-base);
$headings-font-family: $font-family-base;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: inherit;

$btn-font-weight: normal;
$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);
$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);
$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);
$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);
$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);
$btn-link-disabled-color: $gray-light;

$dropdown-bg: #fff;
$dropdown-border: rgba(0,0,0,.15);
$dropdown-divider-bg: #e5e5e5;
$dropdown-link-active-color: #fff;
$dropdown-link-active-bg: $component-active-bg;
$dropdown-link-color: $gray-dark;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: $dropdown-link-active-bg;
$dropdown-link-disabled-color: $gray-light;
$dropdown-header-color: $gray-light;
$dropdown-caret-color: #000;

$input-bg: #fff;
$input-bg-disabled: $gray-lighter;
$input-color: $gray;
$input-border: #ccc;
$input-border-radius: $border-radius-base;
$input-border-focus: $orange;
$input-color-placeholder: $gray-light;
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
$input-height-large: (floor($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);
$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;
$input-group-addon-bg: $gray-lighter;
$input-group-addon-border-color: $input-border;

$table-cell-padding: 8px;
$table-condensed-cell-padding: 5px;
$table-cell-padding: 8px;
$table-condensed-cell-padding: 5px;
$table-bg: transparent;
$table-bg-accent: #f9f9f9;
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;
$table-border-color: #ddd;

$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);
$navbar-default-link-color: $gray;
$navbar-default-link-hover-color: $gray-darker;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $gray-darker;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color:  #ccc;
$navbar-default-link-disabled-bg: transparent;
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-link-color, 10%);
$navbar-default-brand-hover-bg: transparent;
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;
$navbar-inverse-color: #ffffff;
$navbar-inverse-bg: whsl($brand-hue, 50, 80);
$navbar-inverse-border: transparent;
$navbar-inverse-link-color: #ffffff;
$navbar-inverse-link-hover-color: #ffffff;
$navbar-inverse-link-hover-bg: whsl($brand-hue, 50, 100);
$navbar-inverse-link-active-color: white;
$navbar-inverse-link-active-bg: whsl($brand-hue, 50, 100);
$navbar-inverse-link-disabled-color:  #ccc;
$navbar-inverse-link-disabled-bg: transparent;
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-bg: transparent;
$navbar-inverse-toggle-hover-bg: whsl($brand-hue, 50, 100);
$navbar-inverse-toggle-icon-bar-bg: #ffffff;
$navbar-inverse-toggle-border-color: transparent;

$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;
$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;
$nav-tabs-border-color: #ddd;
$nav-tabs-link-hover-border-color: $gray-lighter;
$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: #ddd;
$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: #fff;

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-lighter;
$jumbotron-font-size: ceil(($font-size-base * 1.5));

$pagination-bg: #fff;
$pagination-border: #ddd;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-color: $link-hover-color;
$pagination-hover-border: #ddd;
$pagination-active-bg: $brand-primary;
$pagination-active-color: #fff;
$pagination-active-border: $brand-primary;
$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;
$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;
$pager-hover-bg: $pagination-hover-bg;
$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;
$pager-bg: $pagination-bg;
$pager-disabled-color: $gray-light;

$state-warning-text: $brand-warning;
$state-warning-bg: #ffffff;
$state-warning-border: $brand-warning;
$state-danger-text: $brand-danger;
$state-danger-bg: #ffffff;
$state-danger-border: $brand-danger;
$state-success-text: $brand-success;
$state-success-bg: #ffffff;
$state-success-border: $brand-success;
$state-info-text: $brand-info;
$state-info-bg: #ffffff;
$state-info-border: $brand-info;

$tooltip-max-width: 200px;
$tooltip-color: #fff;
$tooltip-bg: #000;
$tooltip-arrow-width: 5px;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: .9;

$popover-bg: #fff;
$popover-max-width: 276px;
$popover-border-color: rgba(0,0,0,.2);
$popover-fallback-border-color: #ccc;
$popover-title-bg: darken($popover-bg, 3%);
$popover-arrow-width: 10px;
$popover-arrow-color: #fff;
$popover-arrow-outer-width: ($popover-arrow-width + 1);
$popover-arrow-outer-color: rgba(0,0,0,.25);
$popover-arrow-outer-fallback-color: #999;

$list-group-bg: #fff;
$list-group-border: #ddd;
$list-group-border-radius: $border-radius-base;
$list-group-hover-bg: #f5f5f5;
$list-group-active-color: #fff;
$list-group-active-bg: $component-active-bg;
$list-group-active-border: $list-group-active-bg;
$list-group-active-text-color: lighten($list-group-active-bg, 40%);
$list-group-link-color: #555;
$list-group-link-heading-color: #333;

$icon-font-path: "../vendor/bootstrap/icons/";
$icon-font-name: glyphicons-halflings-regular;
